import React from 'react';
import Button from 'antd/lib/button';
import Image from '@components/Image';
import { Link } from 'gatsby';
import MarkdownViewer from '@components/MarkdownViewer';
import CardCategory from '@components/Cards/CardCategory';

const sanitizeContent = (input) => {
  if (typeof input !== 'string') return '';
  return input.replace('\n', '');
};

const Headline = (props) => {
  const title = props.title || 'Untitled';
  const category = props.category || 'Uncategorized';
  const slug = props.slug || 'Undefined';
  const author = props.author || 'Unauthored';
  const date = props.date || 'Undated';
  const image = props.image;
  const thumbnail = props.thumbnail;
  const content = sanitizeContent(props?.content);

  return (
    <section className="headline-container">
      <div className="image-panel">
        <Image
          className="headline-image-container-desktop"
          image={image}
          imgStyle={{ objectFit: `contain` }}
        />
        <Image className="headline-image-container-phone" image={thumbnail} />
      </div>

      <div className="description-panel">
        <CardCategory label={category} />
        <h1 className="headline-title-container">{title}</h1>
        <div className="headline-details-container">
          <h3 className="details-author">by {author}</h3>
          <div className="details-middot _text_grayed_out">&middot;</div>
          <h3 className="details-created-at">{date}</h3>
        </div>
        <div className="headline-content-container">
          <MarkdownViewer
            className="headline-content"
            source={content}
            isPreview={true}
          />
        </div>
        <Link to={slug}>
          <Button type="primary" className="headline-read-more-container">
            Read more
          </Button>
        </Link>
      </div>
    </section>
  );
};

export default Headline;

import { useStaticQuery, graphql } from 'gatsby';
import isEmpty from 'lodash/isEmpty';
import getCourseCardsProps from '@helpers/methods/modifyContents/getCourseCardsProps';
import getCareerAdviceCardsProps from '@helpers/methods/modifyContents/getCareerAdviceCardsProps';
import getResourceCardsProps from '@helpers/methods/modifyContents/getResourceCardsProps';

const GET_CAREER_DEVELOPMENT = graphql`
  {
    latestStrapiCareerAdvices: allStrapiCareerAdvices(
      limit: 1
      sort: { fields: published_date, order: DESC }
      filter: {
        content_status: { value: { eq: "2" } }
        published_date: { ne: null }
      }
    ) {
      nodes {
        strapiId
        title
        slug
        created_at(formatString: "MMM DD, YYYY")
        published_date
        published_at
        content
        category: career_advice_category {
          name
        }

        cover_image {
          source_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
            url
          }
          cropped_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
            url
          }
        }
        cover_image_old: image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }

        thumbnail_image {
          source_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
            }
          }
          cropped_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
            }
          }
        }
        thumbnail_image_old: image_thumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
        }

        creator_id
        created_by {
          username
        }
      }
    }
    recentStrapiAdvicesPhone: allStrapiCareerAdvices(
      skip: 1
      limit: 2
      sort: { fields: published_date, order: DESC }
      filter: { content_status: { value: { eq: "2" } } }
    ) {
      nodes {
        strapiId
        title
        slug
        created_at(formatString: "MMM DD, YYYY")
        published_date
        published_at
        category: career_advice_category {
          name
        }

        cover_image {
          source_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
            url
          }
          cropped_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
            url
          }
        }
        cover_image_old: image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }

        thumbnail_image {
          source_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
            }
            url
          }
          cropped_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
            }
            url
          }
        }
        thumbnail_image_old: image_thumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          url
        }

        creator_id
        author: created_by {
          username
        }
      }
    }
    recentStrapiAdvicesDesktop: allStrapiCareerAdvices(
      skip: 1
      limit: 3
      sort: { fields: published_date, order: DESC }
      filter: { content_status: { value: { eq: "2" } } }
    ) {
      nodes {
        strapiId
        title
        slug
        created_at(formatString: "MMM DD, YYYY")
        published_date
        published_at
        category: career_advice_category {
          name
        }

        cover_image {
          source_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
            url
          }
          cropped_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
            url
          }
        }
        cover_image_old: image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
          url
        }

        thumbnail_image {
          source_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
            }
            url
          }
          cropped_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
            }
            url
          }
        }
        thumbnail_image_old: image_thumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          url
        }

        creator_id
        author: created_by {
          username
        }
      }
    }
    allStrapiResources(
      limit: 2
      sort: { fields: published_date, order: DESC }
    ) {
      nodes {
        id
        title
        slug

        creator_id
        author: created_by {
          username
        }

        thumbnail_image {
          source_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
            }
            url
          }
          cropped_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
            }
            url
          }
        }
        thumbnail_image_old: thumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          url
        }
      }
    }
    allCourses: allStrapiCourses(
      sort: { fields: registration_deadline, order: ASC }
    ) {
      nodes {
        strapiId
        created_at(formatString: "MMM DD, YYYY")
        published_date
        published_at
        content
        courses_category {
          name
        }
        slug
        title
        registration_deadline
        start_date
        end_date
        level {
          label
          value
        }
        cost {
          label
          type
          sponsor_name
          sponsorLogo: sponsor_logo {
            localFile {
              extension
              publicURL
            }
          }
        }
        duration

        creator_id
        created_by {
          username
        }

        location {
          province {
            name
          }
          type
        }
      }
    }

    allELearningCourses: allStrapiCourses(
      limit: 2
      sort: { fields: registration_deadline, order: ASC }
      filter: { location: { elemMatch: { type: { eq: "online" } } } }
    ) {
      nodes {
        strapiId
        created_at(formatString: "MMM DD, YYYY")
        published_date
        published_at
        content
        courses_category {
          name
        }
        slug
        title
        duration
        registration_deadline
        start_date
        end_date
        level {
          label
          value
        }
        cost {
          label
          type
          sponsor_name
          sponsorLogo: sponsor_logo {
            localFile {
              extension
              publicURL
            }
          }
        }

        creator_id
        created_by {
          username
        }

        location {
          province {
            name
          }
          type
        }
      }
    }
  }
`;

const encodeViewAllURL = (rootPath = 'career-advice') => {
  const urlPath = `${rootPath}`;
  return `${urlPath}`;
};

const getFirstItem = (array = []) => {
  return array[0];
};
const sanitizeArray = (array = []) => {
  return array ?? [];
};

const Fetcher = ({ children }) => {
  const {
    recentStrapiAdvicesDesktop,
    recentStrapiAdvicesPhone,
    latestStrapiCareerAdvices,
    allStrapiResources,
    allELearningCourses,
    allCourses,
  } = useStaticQuery(GET_CAREER_DEVELOPMENT);

  const hasNoCareerAdvices = isEmpty(latestStrapiCareerAdvices?.nodes);
  const hasNoRecentAdvices =
    isEmpty(recentStrapiAdvicesDesktop?.nodes) ||
    isEmpty(recentStrapiAdvicesPhone.nodes);

  const allResourcesArticles = getResourceCardsProps(allStrapiResources?.nodes);

  let cardItemsDesktop = sanitizeArray(recentStrapiAdvicesDesktop.nodes);
  cardItemsDesktop = getCareerAdviceCardsProps(cardItemsDesktop);

  let cardItemsPhone = sanitizeArray(recentStrapiAdvicesPhone.nodes);
  cardItemsPhone = getCareerAdviceCardsProps(cardItemsPhone);

  let latestAdvicesData = latestStrapiCareerAdvices?.nodes;
  latestAdvicesData = getCareerAdviceCardsProps(latestAdvicesData);
  latestAdvicesData = getFirstItem(latestAdvicesData);

  const viewAllRedirection = {
    resources: encodeViewAllURL('resources'),
    courses: encodeViewAllURL('courses'),
    careerAdvice: encodeViewAllURL('career-advice'),
  };
  const courseCardItems = getCourseCardsProps(allELearningCourses?.nodes);
  const allCourseCardItems = getCourseCardsProps(allCourses?.nodes);

  const sponsoredCardItems = allCourseCardItems.filter((allCourseCardItem) => {
    const sponsoredItem = allCourseCardItem?.cost?.label;
    return sponsoredItem === 'Sponsored';
  });
  const firstSixItemSponsoredCards = sponsoredCardItems.slice(0, 6);
  const carouselStyle =
    firstSixItemSponsoredCards.length === 1 && 'single-card-wrapper';

  return children({
    latestAdvicesData,
    latestStrapiCareerAdvices,
    hasNoCareerAdvices,
    hasNoRecentAdvices,
    courseCardItems,
    allResourcesArticles,
    viewAllRedirection,
    cardItemsDesktop,
    cardItemsPhone,
    firstSixItemSponsoredCards,
    carouselStyle,
  });
};

export default Fetcher;

import React from 'react';

const SectionHeader = ({ className = '', label = '', children = '' }) => {
  return (
    <div className={`section-header-container ${className}`}>
      <span className="section-header">{label || children}</span>
    </div>
  );
};

export default SectionHeader;

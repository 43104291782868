import sanitizeArray from '../utils/sanitizeArray';
import sortByPublishedDate from '@helpers/methods/sortByPublishedDate';
import selectAuthor from '../utils/selectAuthor';
import selectContentImage from '../utils/selectContentImage';

const generateResourceCardsProps = (resources) => {
  const addAuthor = (destinationContent, sourceContent) => {
    return {
      ...selectAuthor(destinationContent, sourceContent),
    };
  };

  const resourceCardsProps = resources.map((resource) => {
    let resourceCardProps = resource;
    resourceCardProps = addAuthor(resourceCardProps, resource);
    resourceCardProps = selectContentImage(resourceCardProps, resource);
    return resourceCardProps;
  });

  return resourceCardsProps;
};

const getResourceCardsProps = (resources) => {
  let resourceCardsProps = sanitizeArray(resources);
  resourceCardsProps = sortByPublishedDate(resourceCardsProps);
  resourceCardsProps = generateResourceCardsProps(resourceCardsProps);

  return resourceCardsProps;
};

export default getResourceCardsProps;

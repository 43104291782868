import React from 'react';
import Layout from '@components/Layout/LayoutTemplates/JobSeekersLayout';
import Subheader from '@components/Layout/LayoutTemplates/CareerDevelopmentLayout/Subheader';
import SEO from '@components/SEO';
import SubscribeEmail from '@components/SubscribeEmail';
import Main from '@components/Layout/Main';
import StudentTestimonials from '@components/StudentTestimonials';
import isEmpty from 'lodash/isEmpty';
import CorporateSupporters from '@components/CorporateSupporters';
import SectionHeader from '@components/SectionHeader';
import Section from '@components/Section';
import ArrowButton from '@components/ArrowButton';
import UpcomingScheduledCourses from '@modules/Home/UpcomingScheduledCourses';
import Card from '@components/Cards/ArticleCard';
import DataWrapper from '@components/DataWrapper';
import CourseCard from '@components/Cards/CourseCard';
import Headline from '@components/Headline';
import formatPapuaDate from '@helpers/methods/formatZuluTime';
import CardCarousel from '@components/CardCarousel';

const CareerDevelopment = ({
  className,
  path,
  testimonials,
  children,
  isReleased = true,
  data,
  EmptyState = null,
}) => {
  const subscribeEmailProps = {
    postURL: `${process.env.GATSBY_API_URL}/jobseekers-email-subscriptions`,
    ctaHeading: 'Stay Connected!',
    ctaDescription:
      "We'll keep you up to date for latest posts, courses, events and news.",
    successHeading: 'Thank you!',
    successDescription:
      'Thank you for subscribing to our newsletter. You should receive a confirmation email soon.',
    subscriptionInfo: '',
  };

  const {
    hasNoRecentAdvices,
    hasNoCareerAdvices,
    latestAdvicesData,
    viewAllRedirection,
    allResourcesArticles,
    courseCardItems,
    cardItemsPhone,
    cardItemsDesktop,
    firstSixItemSponsoredCards,
    carouselStyle,
  } = data;

  const careerAdviceSlug = latestAdvicesData?.slug;
  const careerAdvice = `/career-development/career-advice/${careerAdviceSlug}`;
  const sampleDate = formatPapuaDate(latestAdvicesData?.published_date);
  const hasNoSampleDate = !sampleDate;
  const actualDate = formatPapuaDate(latestAdvicesData?.published_at);
  const latestAdviceDate = hasNoSampleDate ? actualDate : sampleDate;
  const getSectionVisibilityByArrayLength = (array) => {
    const isNotArrayType = !Array.isArray(array);
    if (isNotArrayType) return false;

    const hasNoArrayContent = array.length <= 0;
    if (hasNoArrayContent) return false;

    return true;
  };

  const latestAdvicesCoverImageUrl = latestAdvicesData?.cover_image?.url
    ? `${process.env.GATSBY_API_URL}${latestAdvicesData?.cover_image?.url}`
    : null;

  const latestAdvicesThumbnailImageUrl = latestAdvicesData?.thumbnail_image?.url
    ? `${process.env.GATSBY_API_URL}${latestAdvicesData?.thumbnail_image?.url}`
    : null;

  return (
    <Layout isMobileComingSoon={false}>
      <SEO title="Career Development" description="Career Development" />
      <Subheader />
      {!isReleased ? (
        <EmptyState />
      ) : (
        <div className={'career-development-layout'}>
          <Main className={className}>
            <DataWrapper isEmpty={hasNoCareerAdvices} empty="">
              <Section className={'career-advices-section'}>
                <Headline
                  slug={careerAdvice}
                  title={latestAdvicesData?.title}
                  category={latestAdvicesData?.category?.name}
                  author={
                    latestAdvicesData?.author?.username ||
                    latestAdvicesData?.author
                  }
                  date={latestAdviceDate}
                  content={latestAdvicesData?.content}
                  image={
                    latestAdvicesData?.cover_image?.localFile?.childImageSharp
                      ?.gatsbyImageData || latestAdvicesCoverImageUrl
                  }
                  thumbnail={
                    latestAdvicesData?.thumbnail_image?.localFile
                      ?.childImageSharp?.gatsbyImageData ||
                    latestAdvicesThumbnailImageUrl
                  }
                />

                <DataWrapper isEmpty={hasNoRecentAdvices} empty="">
                  <div className="recent-advices-section">
                    <SectionHeader label={'Recent advice'} />
                    <div className="recent-advices-card-button-group">
                      <div className="recent-advices-card-wrapper-phone">
                        {cardItemsPhone.map((item) => {
                          const adviceSlugPhone = item?.slug;
                          const adviceLinkPhone = `/career-development/career-advice/${adviceSlugPhone}`;
                          return (
                            <Card
                              data={item}
                              key={item?.Id}
                              link={adviceLinkPhone}
                            />
                          );
                        })}
                      </div>
                      <div className="recent-advices-card-wrapper-desktop">
                        {cardItemsDesktop.map((item) => {
                          const adviceSlug = item?.slug;
                          const adviceLink = `/career-development/career-advice/${adviceSlug}`;
                          return (
                            <Card
                              data={item}
                              key={item?.Id}
                              link={adviceLink}
                            />
                          );
                        })}
                      </div>
                      <ArrowButton
                        label={'More from career advice'}
                        link={viewAllRedirection?.careerAdvice}
                      />
                    </div>
                  </div>
                </DataWrapper>
              </Section>
            </DataWrapper>

            <Section
              className="sponsored-courses-container"
              isVisible={getSectionVisibilityByArrayLength(
                firstSixItemSponsoredCards
              )}
            >
              <CardCarousel
                title="Sponsored courses"
                items={firstSixItemSponsoredCards}
                className={carouselStyle}
                linkRoot="/career-development/courses/"
                type="courseCard"
              />
            </Section>

            <UpcomingScheduledCourses />

            <Section
              className={'e-learning-section'}
              isVisible={getSectionVisibilityByArrayLength(courseCardItems)}
            >
              <SectionHeader label={'E-Learning'} />
              <div className="e-learning-card-button-group">
                <div className="e-learningcard-wrapper">
                  {courseCardItems.map((courseCardItem, idx) => {
                    const courseSlug = courseCardItem?.link;
                    const courseLink = `/career-development/courses/${courseSlug}`;
                    return (
                      <CourseCard
                        {...courseCardItem}
                        key={idx}
                        link={courseLink}
                      />
                    );
                  })}
                </div>
                <ArrowButton
                  label={'View courses'}
                  link={viewAllRedirection?.courses}
                />
              </div>
            </Section>

            <Section
              className={'resources-section'}
              isVisible={getSectionVisibilityByArrayLength(
                allResourcesArticles
              )}
            >
              <SectionHeader label={'Resources'} />
              <div className="resources-card-button-group">
                <div className="resources-card-wrapper">
                  {allResourcesArticles.map((resourcesArticle) => {
                    const resourceSlug = resourcesArticle?.slug;
                    const resourceLink = `/career-development/resources/${resourceSlug}`;
                    return (
                      <Card
                        data={resourcesArticle}
                        key={resourcesArticle?.Id}
                        link={resourceLink}
                      />
                    );
                  })}
                </div>
                <ArrowButton
                  label={'View resources'}
                  link={viewAllRedirection?.resources}
                />
              </div>
            </Section>

            {children}
            {!isEmpty(testimonials) && (
              <StudentTestimonials items={testimonials} />
            )}
            <CorporateSupporters label="Training Partners" />
          </Main>
          {isEmpty(path) && <SubscribeEmail {...subscribeEmailProps} />}
        </div>
      )}
    </Layout>
  );
};

export default CareerDevelopment;
